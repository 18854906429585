import React from 'react';
import PathwayStep from './PathwayStep';
import PathwayTarget from './PathwayTarget';

class Pathway extends React.Component {

    render() {
        const { pathway, size } = this.props;
        return (          
                <div>
                    {pathway && pathway.steps.map(step => (
                        <PathwayStep size={size} step={step} key={step.id} />
                    ))}

                    {pathway && <PathwayTarget size={size} target={pathway.target} /> }
                </div>
        );
    }
}

export default Pathway;