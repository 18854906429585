import React from 'react';

class PlayerList extends React.Component {  

    render() { 
        return (
            <div class="PlayerList">
                { this.props.game.players.sort((a,b) => b.score - a.score).map(player => (
                    <div>
                        <div key={player.publicId} style={{
                            width: '20px',
                            height: '20px',
                            'backgroundImage' : `url(https://icotar.com/avatar/${player.publicId}.svg)`,
                        }}></div>
                        <div class="points">{player.score} points</div>
                    </div>
                ))}
            </div>); 
    }
}

export default PlayerList;