import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore } from 'redux';
import { Provider, connect } from 'react-redux';

const initialState = {};

const countReducer = (state = initialState, action) =>
{
  console.debug('entering reducer with action type [' + action.type + ']');

  switch (action.type) {    
      case "UPDATE_CLIENTSTATE":
        return { ...state,
          game: action.updatedClientState
        }; 
      default:
        return state;
    }
  };
  
let store = createStore(countReducer);

const mapStateToProps = state => {
  return {   
    game: state.game
   };
};

const mapDispatchToProps = dispatch => {
  return {
    handleUpdateClientState: (updatedClientState) =>  dispatch( { type: 'UPDATE_CLIENTSTATE', updatedClientState : updatedClientState } )
  }
};

const Component = ({game, handleUpdateClientState }) => (
  <App game={game} handleUpdateClientState={handleUpdateClientState} />
);
const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Container />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
