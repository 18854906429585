import React from 'react';

class PathwayTarget extends React.Component {

    render() {
        const { target, size } = this.props;
        return (
            <div className="GamePiece PathwayTarget" style={{
                left: `${size * target.x + 1}px`,
                top: `${size * target.y + 1}px`,
                width: `${size - 1}px`,
                height: `${size - 1}px`,
            }}>
                
            </div>
        );
    }
}

export default PathwayTarget;