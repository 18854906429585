import React from 'react';
import Player from './Player';
import Ingredient from './Ingredient';
import Solution from './Solution';

class Board extends React.Component {  

    render() { 
        return (
            <div>
                <div 
                    onClick={this.props.handleBoardClick}
                    className="Board"
                    style={{ width: this.props.game.width, height: this.props.game.height,  backgroundSize: `${this.props.game.cellSize}px ${this.props.game.cellSize}px` }}>

                    {this.props.game.ingredients.map(ingredient => (
                        <Ingredient v={ingredient.ingredientValue} size={this.props.game.cellSize} ingredient={ingredient} key={ingredient.ingredientId} />
                    ))}

                    {this.props.game.players.map(player => (
                        <Player player={player} size={this.props.game.cellSize} key={player.publicId} />
                    ))}

                    {this.props.game.solutions.map(solution => (
                        <Solution ingredients={solution.ingredients} size={this.props.game.cellSize} key={solution.solutionId} />
                    ))}
                    
                </div>
            </div>); 
    }
}

export default Board;