import React from 'react';
import './App.css';
import './Game.css';
import Board from './Board';
import PlayerList from './PlayerList';
import { HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';

let signalrEnvironments = {
  "hypermaths.azurewebsites.net" : "https://hypermaths.azurewebsites.net",
  "hypermaths.com" : "https://hypermaths.com",
  "www.hypermaths.com" : "https://www.hypermaths.com",
  "localhost" : "https://localhost:44316",
};

let signalrBaseUrl = signalrEnvironments[window.location.hostname];      

const connection = new HubConnectionBuilder()
      .withUrl(signalrBaseUrl + "/game")      
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();            

class App extends React.Component {  

  constructor(props)
  {
    super(props);

    let handleUpdateClientState = props.handleUpdateClientState;

    connection.on('joined', function(updatedClientState) {
      handleUpdateClientState(updatedClientState);
    
      document.addEventListener("keydown", function(e){ 
          connection.send('processAction', e.code);
      }, false);
    
    });  
    
    connection.on('updateClientState', function(updatedClientState) {
      handleUpdateClientState(updatedClientState);          
    }); 

  }

  render() { 
    return (
      <div className="App">
        
        { this.props.game ?
              <div>
                <PlayerList game={this.props.game} />
                <Board handleBoardClick={this.handleBoardClick} game={this.props.game} />
              </div> 
              : null
          }
        
      </div>); 
  }

  componentDidMount() {
    if(connection.state !== HubConnectionState.Connected)
    {
      connection.start();
    }    
  }

  handleBoardClick = (event) =>
  {
    var getElementOffset = function(boardRef) {
      const rect = boardRef.getBoundingClientRect();
      const doc = document.documentElement;

      return {
          x: (rect.left + window.pageXOffset) - doc.clientLeft,
          y: (rect.top + window.pageYOffset) - doc.clientTop,
      };
    }

    const elemOffset = getElementOffset(event.currentTarget);

    const offsetX = event.clientX - elemOffset.x;
    const offsetY = event.clientY - elemOffset.y;
    
    const x = Math.floor(offsetX / this.props.game.cellSize);
    const y = Math.floor(offsetY / this.props.game.cellSize);

    console.log('x:' + x + ', y:' + y);

    connection.send('processActionWithTarget', 'SelectCell', { X: x, Y : y });
  }
}

export default App;