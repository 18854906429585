import React from 'react';

class Ingredient extends React.Component {

    render() {
        const { ingredient, size, readonly } = this.props;
        return (
            <div className={`GamePiece ${readonly ? "IngredientReadOnly" : "Ingredient"}`} style={{
                left: `${size * ingredient.x + 1}px`,
                top: `${size * ingredient.y + 1}px`,
                width: `${size - 1}px`,
                height: `${size - 1}px`,
            }}>
                {ingredient.value}
            </div>
        );
    }
}



export default Ingredient;