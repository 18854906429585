import React from 'react';
import Ingredient from './Ingredient';

class Solution extends React.Component {

    render() {
        const { ingredients, size } = this.props;
        return (
                ingredients.map(ingredient => (
                    <Ingredient ingredient={ingredient} size={size} key={ingredient.ingredientId} readonly={true} />
                ))
        );
    }
}

export default Solution;