import React from 'react';
import Pathway from './Pathway';

class Player extends React.Component {

    render() {
        const { player, size } = this.props;
        return (
            <div>
                <div className="GamePiece Player" style={{
                    left: `${size * player.playerX + 1}px`,
                    top: `${size * player.playerY + 1}px`,
                    width: `${size - 1}px`,
                    height: `${size - 1}px`,
                    transition: 'left 150ms, top 150ms',
                    backgroundImage : `url(https://icotar.com/avatar/${player.publicId}.svg)`,
                }}>

                    {player.playerValue && <div style={{                        
                        // border: '2px solid limegreen',
                        background: 'white',
                        position : 'relative',
                        top: '-5px',
                        left: '20px',
                        borderRadius:'50%',
                        width: '20px',
                        height: '20px',
                        fontSize: '11pt',
                        lineHeight: '18px',
                        animation: 'pulse 2s infinite'
                    }}>{player.playerValue}</div>}                  

                </div>            
            <Pathway pathway={player.pathway} size={size}></Pathway>
            </div>
        );
    }
}

export default Player;